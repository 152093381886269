import {
	AlertCenterData,
	IndividualReservation,
	OnboardSales
} from "@models/partnerInsights.model"
import { UtagData } from "@services/TealiumService"
import { GroupReservation as GroupReservationData } from "@models/partnerInsights.model"
import { FutureCompensation as FutureCompensationData } from "@models/partnerInsights.model"
import { CasinoReservation as CasinoReservationData } from "@models/partnerInsights.model"

export interface OnTrackingItem extends UtagData {
	event_name?: string
	row_position?: string[]
	booking_id?: string[]
	brand_code?: string[]
	ship_name?: string[]
	sail_date?: string[]
	depart_port?: string[]
	timeframe?: string
	alert_flag?: string[]
	group_id?: string[]
	alert_type?: string[]
	alert_due_date?: string[]
	dueDate?: string[]
}

interface EventTrackingItemsProps extends UtagData {
	event_name?: string
	page_url?: string
	alert_bar_content?: string
	interaction_type?: string
	category?: string
	event211?: number
	link_url?: string
	search_term?: string
	search_bar_name?: string
	nav_link_text?: string
	click_text?: string
	button_click?: string
	reward_balance?: string
	event222?: number
	chatbot_interaction_type?: string
	event214?: number
	frequency?: string
	training_type?: string
	box_filter?: string | object
}

interface EventTrackingProps {
	event_name: string
	action?: string
	interactionType?: string
	category?: string
	event211?: number
	link_url?: string
	search_term?: string
	searchbarName?: string
	navLinkText?: string
	clickText?: string
	buttonClick?: string
	rewardBalance?: string
	event222?: number
	chatbot_interaction_type?: string
	event214?: number
	frequency?: string
	training_type?: string
	box_filter?: string | string[] | object
}

interface EventFeaturedTrackingProps {
	event_name: string
	row_position?: string[]
	offer_unique_id?: string[]
	offer_title?: string[]
	offer_start_date?: string[]
	offer_end_date?: string[]
	brand_code?: string[]
	expired_offer?: string[]
	offers_for?: string[] | undefined
	destination?: string[] | undefined
	box_filter?: string[] | undefined
	navLinkText?: string
	category?: string
	interaction_type?: string
	link_url?: string
	ship?: string[] | undefined
}
interface EventFeaturedTrackingItemProps
	extends EventFeaturedTrackingProps,
		UtagData {
	page_url?: string
}

interface EventPromotionTrackingItemProps
	extends EventPromtionItemsProps,
		UtagData {
	page_url?: string
	p_ship_code?: string | string[]
	p_brand?: string | string[]
	p_sail_date?: string | string[]
	p_itinerary_code?: string | string[]
	p_package_id?: string | string[]
	p_cabin_class?: string[]
	p_depart_port?: string | string[]
	promo_name?: string[]
}

export interface EventPromtionItemsProps {
	event_name: string
	row_position?: string[]
	promo_id?: (string | number)[]
	p_ship_code?: string | string[]
	p_brand?: string | string[]
	p_sail_date?: string | string[]
	p_itinerary_code?: string | string[]
	p_package_id?: string | string[]
	product_quantity?: string[]
	p_cabin_class?: string[]
	currencyCode?: string[]
	product_price?: string[] | number[]
	p_depart_port?: string | string[]
	promo_name?: string[]
}

type EventRow =
	| OnboardSales
	| GroupReservationData
	| FutureCompensationData
	| CasinoReservationData
	| IndividualReservation
	| AlertCenterData

export function isOnboardSales(row: EventRow): row is OnboardSales {
	return (row as OnboardSales).depositExpiring !== undefined
}

export function isGroupReservationData(
	row: EventRow
): row is GroupReservationData {
	return (row as GroupReservationData).totalRooms !== undefined
}

export function isFutureCompensationData(
	row: EventRow
): row is FutureCompensationData {
	return (row as FutureCompensationData).compensationType !== undefined
}

export function isCasinoReservation(
	row: EventRow
): row is CasinoReservationData {
	return (row as CasinoReservationData).notes !== undefined
}

export function isIndividualReservation(
	row: EventRow
): row is IndividualReservation {
	const bookingId = (row as IndividualReservation).flexPay
	return typeof bookingId === "string" && bookingId.length > 0
}

export function isAlertCenter(row: EventRow): row is AlertCenterData {
	return (row as AlertCenterData).alerts !== undefined
}

const extractTrackingValues = (row: EventRow, index?: number) => {
	let shipNames: string[] = []
	let bookingIds: string[] = []
	let alertFlags: string[] = []
	let groupIds: string[] = [""]
	let dueDate: string[] = []
	let timeframe: string | undefined = ""
	let alertTypes: string[] = [""]
	let brandCodes: string[] = []
	let sailDates: string[] = []
	let alertDueDates: string[] = [""]

	const rowPosition: string[] = [(index ?? 0 + 1).toString()]
	const departPorts: string[] = [""]

	switch (true) {
		case isOnboardSales(row):
			shipNames = [row.ship || ""]
			bookingIds = [row.bookingId?.toString() || ""]
			alertFlags = [row?.alerts ? "1" : "0"]
			groupIds = [row?.groupId?.toString() || ""]
			brandCodes = [row?.brand || ""]
			sailDates = [
				row?.sailingDate
					? new Date(row.sailingDate).toLocaleDateString("en-US")
					: ""
			]
			break

		case isGroupReservationData(row):
			shipNames = [row?.shipName ?? row?.ship ?? ""]
			alertFlags = [row?.alerts ? "1" : "0"]
			groupIds = [row?.groupId?.toString() || ""]
			timeframe = row?.timeframe
			sailDates = [
				row?.sailDate ? new Date(row.sailDate).toLocaleDateString("en-US") : ""
			]
			brandCodes = [row?.brand || ""]
			break

		case isFutureCompensationData(row):
			shipNames = [row.shipName || ""]
			bookingIds = [row.bookingId?.toString() || ""]
			alertFlags = [row?.alerts ? "1" : "0"]
			groupIds = [row?.groupId?.toString() || ""]
			brandCodes = [row?.brand || ""]
			sailDates = [
				row?.sailingDate
					? new Date(row.sailingDate).toLocaleDateString("en-US")
					: ""
			]
			break

		case isCasinoReservation(row):
			shipNames = [row.ship || ""]
			bookingIds = [row.bookingId?.toString() || ""]
			brandCodes = [row?.brand || ""]
			sailDates = [
				row?.sailingDate
					? new Date(row.sailingDate).toLocaleDateString("en-US")
					: ""
			]
			break

		case isIndividualReservation(row):
			shipNames = [row.shipName || ""]
			bookingIds = [row.bookingId ? row.bookingId?.toString() : ""]
			alertFlags = [row?.alerts ? "1" : "0"]
			groupIds = [row?.groupId?.toString() || ""]
			dueDate = [
				row?.balanceDue
					? new Date(row?.balanceDue?.date).toLocaleDateString("en-US")
					: ""
			]
			alertTypes = ["dinningConflict"]
			brandCodes = [row?.brand || ""]
			sailDates = [
				row?.sailingDate
					? new Date(row.sailingDate).toLocaleDateString("en-US")
					: ""
			]
			break

		case isAlertCenter(row):
			shipNames = [row.shipName || ""]
			bookingIds = [row.bookingId?.toString() || ""]
			alertFlags = [row?.alerts ? "1" : "0"]
			alertTypes = [row?.alerts[0]?.type || ""]
			alertDueDates = [row?.date || ""]
			brandCodes = [row?.brandCode || ""]
			groupIds = [row?.groupId?.toString() || ""]
			sailDates = [row?.alerts[0]?.dueDate || ""]
			break

		default:
			shipNames = [""]
			break
	}
	return {
		ship_name: shipNames,
		row_position: rowPosition,
		booking_id: bookingIds,
		brand_code: brandCodes,
		sail_date: sailDates,
		depart_port: departPorts,
		alert_flag: alertFlags,
		group_id: groupIds,
		timeframe: timeframe,
		alert_type: alertTypes,
		alert_due_date: alertDueDates,
		dueDate: dueDate
	}
}

export const eventTracking = (
	row?: EventRow,
	index?: number,
	eventName?: string
): OnTrackingItem | null => {
	if (!row) return null

	const extractedValues = extractTrackingValues(row, index)
	return {
		event_name: eventName || "",
		...extractedValues
	}
}

export const sendEventTracking = (payloads: OnTrackingItem[]): void => {
	if (payloads.length > 0) {
		const shipNames: string[] = []
		const rowPositions: string[] = []
		const bookingIds: string[] = []
		const brandCodes: string[] = []
		const sailDates: string[] = []
		const departPorts: string[] = []
		const alertFlags: string[] = []
		const groupIds: string[] = []
		const alertType: string[] = []
		const alertDueDate: string[] = []
		const dueDate: string[] = []
		let timeframe = ""
		let eventName = ""

		for (const payload of payloads) {
			shipNames.push(...(payload.ship_name || []))
			rowPositions.push(...(payload.row_position || []))
			bookingIds.push(...(payload.booking_id || []))
			brandCodes.push(...(payload.brand_code || []))
			sailDates.push(...(payload.sail_date || []))
			departPorts.push(...(payload.depart_port || []))
			alertFlags.push(...(payload.alert_flag || []))
			groupIds.push(...(payload.group_id || []))
			alertType.push(...(payload.alert_type || []))
			alertDueDate.push(...(payload.alert_due_date || []))
			dueDate.push(...(payload.dueDate || []))
			timeframe = payload.timeframe || ""
			eventName = payload.event_name || ""
		}

		const finalPayload: OnTrackingItem = {
			event_name: eventName,
			ship_name: shipNames,
			row_position: rowPositions,
			booking_id: bookingIds,
			brand_code: brandCodes,
			sail_date: sailDates,
			depart_port: departPorts,
			alert_flag: alertFlags,
			alert_type: alertType,
			alert_due_date: alertDueDate,
			dueDate: dueDate,
			group_id: groupIds,
			timeframe: timeframe
		}
		window.utag.link(finalPayload)
	}
}

export const eventTrackingItems = ({
	event_name,
	action,
	interactionType,
	category,
	event211,
	link_url,
	search_term,
	searchbarName,
	navLinkText,
	clickText,
	buttonClick,
	rewardBalance,
	event222,
	chatbot_interaction_type,
	event214,
	frequency,
	training_type,
	box_filter
}: EventTrackingProps): void => {
	const payload: EventTrackingItemsProps = {
		event_name: event_name,
		page_url: window.location.href,
		alert_bar_content: action,
		interaction_type: interactionType,
		category: category,
		event211: event211 || 0,
		link_url: link_url,
		search_term: search_term,
		search_bar_name: searchbarName,
		nav_link_text: navLinkText,
		click_text: clickText,
		button_click: buttonClick,
		reward_balance: rewardBalance,
		event222: event222 || 0,
		chatbot_interaction_type: chatbot_interaction_type,
		event214: event214 || 0,
		frequency: frequency,
		training_type: training_type,
		box_filter: box_filter || ""
	}
	window.utag.link(payload)
}

export const eventFeaturedTrackingItems = ({
	event_name,
	row_position,
	offer_unique_id,
	offer_title,
	offer_start_date,
	offer_end_date,
	brand_code,
	expired_offer,
	offers_for,
	destination,
	box_filter,
	navLinkText,
	category,
	interaction_type,
	link_url,
	ship
}: EventFeaturedTrackingProps): void => {
	const payload: EventFeaturedTrackingItemProps = {
		event_name: event_name,
		page_url: window.location.href,
		row_position: row_position,
		offer_unique_id: offer_unique_id,
		offer_title: offer_title,
		offer_start_date: offer_start_date,
		offer_end_date: offer_end_date,
		brand_code: brand_code,
		expired_offer: expired_offer,
		offers_for: offers_for,
		destination: destination,
		box_filter: box_filter,
		navLinkText: navLinkText,
		category: category,
		interaction_type: interaction_type,
		link_url: link_url,
		ship: ship
	}

	window.utag.link(payload)
}

export const eventPromotionItems = ({
	event_name,
	row_position,
	promo_id,
	p_ship_code,
	p_brand,
	p_sail_date,
	p_itinerary_code,
	p_package_id,
	product_quantity,
	p_cabin_class,
	currencyCode,
	product_price,
	p_depart_port,
	promo_name
}: EventPromtionItemsProps): void => {
	const payload: EventPromotionTrackingItemProps = {
		event_name: event_name,
		page_url: window.location.href,
		row_position: row_position,
		promo_id: promo_id,
		p_ship_code: p_ship_code,
		p_brand: p_brand,
		p_sail_date: p_sail_date,
		p_itinerary_code: p_itinerary_code,
		p_package_id: p_package_id,
		product_quantity: product_quantity,
		p_cabin_class: p_cabin_class,
		currencyCode: currencyCode,
		product_price: product_price,
		p_depart_port: p_depart_port,
		promo_name: promo_name
	}
	window.utag.link(payload)
}
