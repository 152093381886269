import { Box, Collapse, UnstyledButton } from "@mantine/core"
import { IconCaretLeftFilled, IconCaretRightFilled } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { useRedirect } from "@hooks/useRedirect"
import classes from "./LinksGroup.module.css"
import { AEMSvgData } from "@components/AEMSvg"
import { eventTrackingItems } from "@utils/eventTracking"

export type LinkType =
	| "internal"
	| "internal-blank"
	| "external"
	| "route"
	| "public"

export interface LinkItem {
	label?: string
	icon?: AEMSvgData | string
	link?: string
	type?: LinkType
	appId?: string | string[]
	links?: LinkItem[]
	toolCode?: string
	params?: Map<string, string>
	count?: number
	retainBasePath?: boolean
}

interface LinksGroupProps {
	label: string
	initiallyOpened?: boolean
	links?: LinkItem[]
	menuOpened: boolean
}

function LinksGroup({
	label,
	initiallyOpened,
	links,
	menuOpened
}: LinksGroupProps) {
	const { handleRedirect } = useRedirect()
	const [opened, setOpened] = useState(initiallyOpened || false)
	const hasLinks = Array.isArray(links)

	const ChevronIcon = opened ? IconCaretLeftFilled : IconCaretRightFilled
	const chevronId = opened ? "icon-chevron-left" : "icon-chevron-right"

	const handleToggle = () => {
		setOpened((o) => !o)
		eventTrackingItems({
			event_name: "navigation_click",
			navLinkText: label || "",
			interactionType: "Sidebar menu click",
			category: "Navigation",
			link_url: ""
		})
	}

	useEffect(() => {
		setOpened(menuOpened)
	}, [menuOpened])

	const redirectTo = (item: LinkItem, hasNestedLinks?: boolean) => {
		handleRedirect(item)
		eventTrackingItems({
			event_name: "navigation_click",
			navLinkText: !hasNestedLinks ? item.label : label,
			interactionType: "Sidebar menu click",
			category: "Navigation",
			link_url: item.link || ""
		})
	}

	const renderLinks = (items: LinkItem[], depth = 0) => {
		return items.map((item, index) => {
			const [nestedOpened, setNestedOpened] = useState(false)
			const hasNestedLinks = Array.isArray(item.links) && item.links.length > 0
			const isLastItem = index === items.length - 1

			const handleNestedToggle = (e: React.MouseEvent) => {
				e.preventDefault()
				e.stopPropagation()
				setNestedOpened((o) => !o)
				const nestedLinks = item.links || []

				if (hasNestedLinks && nestedLinks.length > 0) {
					eventTrackingItems({
						event_name: "navigation_click",
						navLinkText: item.label || "",
						interactionType: "Submenu click",
						category: "Navigation",
						link_url: ""
					})
				}
			}

			const NestedChevronIcon = nestedOpened
				? IconCaretLeftFilled
				: IconCaretRightFilled

			return (
				<Box
					key={item.label}
					pl={depth * 6}
					className={classes.linksGroupContainer}
				>
					<UnstyledButton
						className={`${classes.linksGroupButton} ${classes.subLink}`}
						style={{
							borderBottom: !isLastItem
								? "1px solid var(--background-color-primary)"
								: "none"
						}}
						onClick={
							hasNestedLinks
								? handleNestedToggle
								: () => redirectTo(item, hasNestedLinks)
						}
						aria-expanded={hasNestedLinks && nestedOpened ? "true" : "false"}
					>
						<Box>{item.label}</Box>
						{hasNestedLinks && (
							<NestedChevronIcon
								className={classes.chevron}
								size="1rem"
								style={{
									transform: nestedOpened ? "rotate(-270deg)" : "rotate(0deg)"
								}}
							/>
						)}
					</UnstyledButton>
					{hasNestedLinks && item.links && (
						<Collapse in={nestedOpened}>
							{renderLinks(item.links, depth + 1)}
						</Collapse>
					)}
				</Box>
			)
		})
	}

	return (
		<>
			<UnstyledButton
				onClick={handleToggle}
				className={`${classes.linksGroupButton} ${classes.topLevelLink}`}
				aria-expanded={opened ? "true" : "false"}
			>
				<Box ml="md">{label}</Box>
				{hasLinks && (
					<ChevronIcon
						data-test-id={chevronId}
						id={chevronId}
						size="1rem"
						stroke={1.5}
						style={{
							transform: opened ? "rotate(-270deg)" : "rotate(90deg)"
						}}
					/>
				)}
			</UnstyledButton>
			{hasLinks && (
				<Collapse className={classes.linksGroupContainer} in={opened}>
					{renderLinks(links)}
				</Collapse>
			)}
		</>
	)
}

export default LinksGroup
