import { WebChatWidget } from "@components/WebChatWidget/WebChatWidget"
import { AlertBar } from "@components/ui/AlertBar/AlertBar"
import {
	AppShell,
	Box,
	Flex,
	Group,
	useMantineColorScheme,
	useMantineTheme
} from "@mantine/core"
import { useEffect, useRef, useState } from "react"
import AppFooter from "../../../navigation/AppFooter"
import AppHeader from "../../../navigation/AppHeader"
import AppMainMenu from "../../../navigation/AppMainMenu"
import styles from "./PageWithNavigation.module.css"
import { useAppShellContext } from "../../../context/AppShellProvider"
import { AEMComponentMapper, AEMPageType } from "@AEM/models/AEMPage.model"
import { useAEMComponentData } from "@AEM/AEMContentLoader"
import { AEMSvg } from "@components/AEMSvg"
import { ColorScheme } from "@mantine/styles"
import { useLocation } from "react-router-dom"

export function PageWithNavigation({
	children
}: { children: React.ReactNode }) {
	const theme = useMantineTheme()
	const { isMenuOpen } = useAppShellContext()
	const [alertBarOpen, setAlertBarOpen] = useState(true)
	const { colorScheme } = useMantineColorScheme()
	const { pathname } = useLocation()
	const scrollBoxRef = useRef<HTMLDivElement | null>(null)

	const navBarClasses = [styles.NavBar, styles.NavBarWithTransition]
	if (isMenuOpen) navBarClasses.push(styles.NavBarOpen)

	// TODO: Santiago Ponce: Should be changed with a global aem link, we are using login images for V1
	const { componentData } = useAEMComponentData(
		AEMPageType.LOGIN,
		AEMComponentMapper.LOGIN_FORM
	)
	const subsidiaryLogos = componentData?.additionalLogos

	interface CruiseLineLogo {
		dark: React.ReactNode
		light: React.ReactNode
	}

	const brandLogos: Record<string, CruiseLineLogo> = {
		royal: {
			light: (
				<AEMSvg
					svgData={{ path: subsidiaryLogos?.[0]?.path }}
					w={{ base: 69, sm: 86 }}
					h={{ base: 18, sm: 22 }}
				/>
			),
			dark: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[0]?.path,
						iconColor: theme.colors.royal[6]
					}}
					w={{ base: 69, sm: 86 }}
					h={{ base: 18, sm: 22 }}
				/>
			)
		},
		celebrity: {
			light: (
				<AEMSvg
					svgData={{ path: subsidiaryLogos?.[1]?.path }}
					w={{ base: 86, sm: 106 }}
					h={{ base: 18, sm: 22 }}
				/>
			),
			dark: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[1]?.path,
						iconColor: theme.colors.royal[6]
					}}
					w={{ base: 86, sm: 106 }}
					h={{ base: 18, sm: 22 }}
				/>
			)
		},
		silversea: {
			light: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[2]?.path,
						iconColor: theme.colors.royal[4]
					}}
					w={{ base: 70, sm: 92 }}
					h={{ base: 9, sm: 12 }}
				/>
			),
			dark: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[2]?.path,
						iconColor: theme.colors.royal[6]
					}}
					w={{ base: 70, sm: 92 }}
					h={{ base: 9, sm: 12 }}
				/>
			)
		}
	}

	useEffect(() => {
		if (scrollBoxRef.current) {
			scrollBoxRef.current.scrollTo(0, 0)
		}
	}, [pathname])

	return (
		<AppShell
			layout="alt"
			header={{ height: { base: alertBarOpen ? 148 : 70, sm: 70 } }}
			footer={{ height: { base: "auto" } }}
			navbar={{
				width: isMenuOpen ? 320 : 72,
				breakpoint: "sm"
			}}
			ml={0}
			padding={0}
			transitionDuration={150}
			transitionTimingFunction="ease"
		>
			<Flex wrap="nowrap" gap={0} className={styles.TopBar}>
				<Box style={{ width: "100%" }}>
					{alertBarOpen && (
						<AlertBar
							onClose={() => setAlertBarOpen(false)}
							className={styles.AlertBar}
						/>
					)}
				</Box>
				<Group className={styles.brandIcons}>
					{brandLogos.royal[colorScheme as ColorScheme]}
					{brandLogos.celebrity[colorScheme as ColorScheme]}
					{brandLogos.silversea[colorScheme as ColorScheme]}
				</Group>
			</Flex>
			<AppHeader />
			<AppShell.Navbar withBorder={false} className={navBarClasses.join(" ")}>
				<AppMainMenu />
			</AppShell.Navbar>
			<AppShell.Main className={styles.Main}>
				<Box ref={scrollBoxRef} className={styles.MainScrollBox}>
					<Box
						mih={{ base: "calc(100dvh - 632px)", sm: "calc(100dvh - 462px)" }}
					>
						{children}
					</Box>
					<AppShell.Footer pos="static">
						<AppFooter useDivider />
					</AppShell.Footer>
				</Box>
			</AppShell.Main>
			<WebChatWidget />
		</AppShell>
	)
}
