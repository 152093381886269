import { ThemeIcon, Tooltip, UnstyledButton } from "@mantine/core"
import classes from "./IconLink.module.css"
import { useRedirect } from "../hooks/useRedirect"
import { LinkItem } from "./LinksGroup"
import { AEMSvg } from "@components/AEMSvg"
import { eventTrackingItems } from "@utils/eventTracking"

interface IconLinkProps {
	item: LinkItem
}

export function IconLink({ item }: IconLinkProps) {
	const { handleRedirect } = useRedirect()
	const iconPath =
		typeof item.icon === "string" ? item.icon : item.icon?.path ?? ""

	const handleClick = (item: LinkItem) => {
		handleRedirect(item)
		eventTrackingItems({
			event_name: "navigation_click",
			interactionType: "Sidebar menu click",
			category: "Navigation",
			link_url: item.link || ""
		})
	}

	return (
		<UnstyledButton
			className={classes.iconLink}
			onClick={() => handleClick(item)}
		>
			<Tooltip
				withArrow={true}
				arrowSize={29}
				arrowOffset={8}
				arrowRadius={3}
				arrowPosition={"side"}
				position={"right-end"}
				color={"var(--mantine-color-body)"}
				c={"var(--mantine-color-text)"}
				fw={600}
				mt={2}
				ml={10}
				label={item.label ?? ""}
				pt={10}
				pb={10}
				pl={20}
				pr={20}
				fz={16}
				styles={{
					tooltip: {
						boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
						borderTopRightRadius: "10px",
						borderBottomRightRadius: "10px"
					}
				}}
			>
				<ThemeIcon variant="transparent" className={classes.iconTheme}>
					{item && (
						<AEMSvg
							ariaRole="link"
							svgData={{
								path: iconPath,
								title: item.label ?? "",
								description: item.label ?? ""
							}}
						/>
					)}
				</ThemeIcon>
			</Tooltip>
		</UnstyledButton>
	)
}
