import { Drawer, MantineBreakpoint, UnstyledButton } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { ReactElement } from "react"
import styles from "./DrawerWithTrigger.module.css"

import XIcon from "@assets/svg/x.svg?react"
import { eventTrackingItems } from "@utils/eventTracking"

interface DrawerWithTriggerProps {
	triggerIcon: ReactElement | string
	drawerContent: ReactElement
	withHeader?: boolean
	title?: string
	visibleFrom?: MantineBreakpoint
	toggleOverride?: () => void
	openOverride?: boolean
	location?: "header" | "account-menu"
	openTriggerLabel?: string
	closeTriggerLabel?: string
}

const DrawerWithTrigger = ({
	triggerIcon,
	drawerContent,
	withHeader = true,
	title,
	visibleFrom = "base",
	toggleOverride,
	openOverride,
	location = "header",
	openTriggerLabel,
	closeTriggerLabel
}: DrawerWithTriggerProps) => {
	const [opened, { open, close }] = useDisclosure(false)

	const handleWidgetToggle = (action: "open" | "close") => {
		if (action === "open") {
			toggleOverride ? toggleOverride() : open()
		} else {
			toggleOverride ? toggleOverride() : close()
		}
		const actionItem = action === "open" ? "Widget open" : "Widget close"
		eventTrackingItems({
			event_name: "navigation_click",
			navLinkText: title || "",
			interactionType: actionItem,
			category: "Header Menu click",
			link_url: "",
			event211: 1
		})
	}

	return (
		<>
			<UnstyledButton
				aria-label={openTriggerLabel}
				visibleFrom={visibleFrom}
				display="flex"
				onClick={() => handleWidgetToggle("open")}
				variant={location === "header" ? "icon-hover" : "hover"}
			>
				{triggerIcon}
			</UnstyledButton>
			<Drawer
				visibleFrom={visibleFrom}
				size="sm"
				withCloseButton={withHeader}
				closeButtonProps={{ "aria-label": closeTriggerLabel }}
				opened={openOverride === true || opened}
				onClose={() => handleWidgetToggle("close")}
				title={title}
				position="right"
				overlayProps={{
					backgroundOpacity: 0,
					blur: 0,
					bg: "transparent"
				}}
				classNames={styles}
			>
				{!withHeader && (
					<UnstyledButton
						aria-label={closeTriggerLabel}
						display="flex"
						pos="absolute"
						top={24}
						right={24}
						onClick={() => handleWidgetToggle("close")}
					>
						<XIcon fill="#000000" />
					</UnstyledButton>
				)}
				{drawerContent}
			</Drawer>
		</>
	)
}

export default DrawerWithTrigger
