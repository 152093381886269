import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { LinkItem } from "../navigation/LinksGroup"
import { AuthService } from "../services/authService"
import {
	BASE_LEGACY_URL,
	CREATE_ACCOUNT_LEGACY_APP_ID,
	FORGOT_PASSWORD_LEGACY_APP_ID,
	SSO_PARAM,
	TOKEN_PARAM,
	APP_ID,
	TOKEN_ID_PARAM,
	APP_ID_PARAM
} from "../utils/constants.util"
import { useAppShellContext } from "../context/AppShellProvider"
import { StorageKeys } from "@utils/user-config"
import secureLocalStorage from "react-secure-storage"
import { gzip } from "pako"
import { useUserStore } from "./useUserStore"

const santitizeAppId = (appId: string | string[] | undefined) =>
	typeof appId === "string" || !appId
		? appId
		: appId[0] && appId[0].split("/").length > 1
			? appId[0].split("/")[1]
			: undefined

export const useRedirect = () => {
	const navigate = useNavigate()
	const { closeMenu } = useAppShellContext()
	const { userProfile } = useUserStore()

	const handleRedirect = useCallback(
		async ({ link, type, appId, params, retainBasePath }: LinkItem) => {
			if (!link || !type) {
				return console.error("Unknown link or type")
			}

			closeMenu?.()
			switch (type) {
				case "internal-blank": {
					const internalUrl = await buildInternalRedirectUrl(link, params)
					if (internalUrl) window.open(internalUrl, "_blank")
					break
				}
				case "internal": {
					const internalUrl = await buildInternalRedirectUrl(link, params)
					secureLocalStorage.setItem(
						StorageKeys.HAS_USER_NAVIGATED_TO_LEGACY,
						"true"
					)
					if (internalUrl) window.location.href = internalUrl
					break
				}
				case "external": {
					if (appId === APP_ID.EQUOTE && !userProfile?.eQuoteAcceptanceTC) {
						handleRedirect({
							link: "eQuote",
							type: "internal"
						})
						break
					}
					if (appId === APP_ID.HYBRIS) {
						const primaryCurrency =
							userProfile?.currencies?.find(
								(currency) => currency.primaryCurrency
							)?.currencyCode || ""
						if (!params) {
							params = new Map<string, string>()
						}
						params.set("cur", primaryCurrency)
					}
					const sanitizedAppId = santitizeAppId(appId)
					const externalUrl = await buildExternalRedirectUrl(
						link,
						sanitizedAppId,
						params
					)
					if (externalUrl) window.open(externalUrl, "_blank")
					break
				}
				case "route":
					retainBasePath ? navigate(`.${link}`) : navigate(link)
					break
				case "public":
					if (
						appId === CREATE_ACCOUNT_LEGACY_APP_ID ||
						appId === FORGOT_PASSWORD_LEGACY_APP_ID
					) {
						window.open(link, "_self")
						break
					}
					window.open(link, "_blank")
					break
				default:
					console.error("Unknown link type")
			}
		},
		[navigate, closeMenu]
	)

	const buildInternalRedirectUrl = async (
		target: string,
		params?: Map<string, string>
	) => {
		try {
			const token = AuthService.getAuthToken()
			if (!token) {
				return null
			}

			const remoteToken = await AuthService.remoteCPAccess(target)

			if (!remoteToken.remote_token) {
				return null
			}

			const urlObj = new URL(BASE_LEGACY_URL)
			const allParams: string[] = []
			allParams.push(`token=${encodeURIComponent(remoteToken.remote_token)}`)
			if (params) {
				params.forEach((value, key) =>
					allParams.push(
						`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
					)
				)
			}
			const compressedParams = gzip(allParams.join("&"))
			const base64Encoded = btoa(
				Array.from(compressedParams)
					.map((c) => String.fromCharCode(c as number))
					.join("")
			)
			urlObj.searchParams.set("params", base64Encoded)
			return urlObj.toString()
		} catch (_error) {
			return null
		}
	}

	const buildExternalRedirectUrl = async (
		link: string,
		appId?: string,
		params?: Map<string, string>
	) => {
		const agencyId = userProfile?.agencyId
		const agentId = userProfile?.agentId

		const urlObj = new URL(link)
		if (
			link.includes("agency_id") &&
			link.includes("tagent_id") &&
			agencyId &&
			agentId
		) {
			urlObj.searchParams.set("agency_id", agencyId.toString())
			urlObj.searchParams.set("tagent_id", agentId.toString())
			return urlObj.href
		}

		if (params) {
			params.forEach((value, key) => urlObj.searchParams.set(key, value))
		}

		try {
			if (!appId) {
				return null
			}

			const externalToken = await AuthService.webserviceAccess(appId)
			if (!externalToken) {
				return null
			}

			if (appId !== APP_ID.IFRNDS) {
				urlObj.searchParams.append(APP_ID_PARAM, appId)
			}
			if (appId === APP_ID.EQUOTE || appId === APP_ID.VERB) {
				urlObj.searchParams.append(SSO_PARAM, externalToken.remoteAccessToken)
			} else if (appId === APP_ID.GMU) {
				urlObj.searchParams.append(
					TOKEN_ID_PARAM,
					externalToken.remoteAccessToken
				)
			} else {
				urlObj.searchParams.append(TOKEN_PARAM, externalToken.remoteAccessToken)
			}

			return urlObj.href
		} catch (_error) {
			return null
		}
	}

	const goToEspresso = ({
		bookingId,
		groupId
	}: { bookingId?: string; groupId?: string }) => {
		let json = ""
		if (bookingId) {
			json = JSON.stringify({
				reservationId: bookingId
			})
		} else {
			json = JSON.stringify({
				groupId
			})
		}
		handleRedirect({
			link: "espresso-search",
			type: "internal",
			params: new Map<string, string>([["predictiveSearchCriteriaJson", json]])
		})
	}

	return { handleRedirect, buildInternalRedirectUrl, goToEspresso }
}
