import { AEMImageData } from "@components/AEMImage"
import { MediaPositionType } from "@components/ui/CompoundSideMedia/CompoundSideMedia"
import { LinkItem } from "navigation/LinksGroup"
import { Description } from "pages/see-our-ships/ResourceDetails/ResourceDetails"

export enum EntityPathParams {
	BRAND = "${BRAND}",
	SHIP = "-${SHIP}"
}

export interface AEMCache {
	[locale: string]: {
		[page: string]: AEMPage
	}
}

export interface AEMPage {
	items: [
		{
			title?: string
			brand?: BrandHeroType
			id?: string
			components: AEMComponentType[]
			items?: AEMComponentType[]
			detailModel?: AEMComponentType
		}
	]
}

export interface AEMLeafNode {
	children?: {
		items: (AEMSectionNode | AEMLeafNode)[]
	}
	label: string
	description: AEMDescriptionType
	toolCode: string
	link: string
	type: string
	section: boolean
	icon?: string
	appId?: string
}

export interface AEMSectionNode {
	label: string
	description: AEMDescriptionType
	section: boolean
	children: {
		items?: (AEMSectionNode | AEMLeafNode)[]
	}
}

export interface AEMDescriptionType {
	markdown: string
	plaintext: string
}

export interface AEMNavigation {
	[key: number]: {
		children: {
			items: (AEMSectionNode | AEMLeafNode)[]
		}
	}
}

export interface AEMAlert {
	data: {
		componentAlertBannerList?: { items: unknown[] }
		componentNotificationsList?: { items: unknown[] }
	}
}

export interface AEMComponentType {
	type: string
	query?: string
	// biome-ignore lint/suspicious/noExplicitAny: Used any since any type of key value pair could come from AEM
	[key: string]: any
}

export interface AEMContent {
	// biome-ignore lint/suspicious/noExplicitAny: Used any since any type of key value pair could come from AEM
	[key: string]: any
}

export interface AEMPromotionSocialIcon {
	publishUrl: string
	path: string
	title: string
	description: string
	color?: string
}

export interface AEMPromotionMarketingAssetLink {
	label: string
	link: string
	type: string
	toolCode: string | null
	appId: string | null
	office: string | null
	country: string | null
	brand: string | null
}

export interface AEMPromotionOffer {
	uniqueId: string
	office: string | null
	title: string
	subtitle: string
	heroImage: AEMImageData
	cardImage: AEMImageData
	featuredBannerPriority: number
	featuredBannerDescription: string
	offerStart: string
	offerEnd: string
	promotionStart: string
	packageCode: string
	fareCode: string
	fareName: string
	promoType: string
	country: string[]
	brandReference: {
		_path: string
		brandName: string
		brandCode: string
	}
	ships: string[]
	destination: string[]
	collection: string[] | null
	collectionPriority: number
	offerName: string[]
	offerFor: string[]
	flagTitle: string | null
	flagDescription: string | null
	ctaLabel: string
	bookingWindowHeader: string | null
	bookingWindow: string | null
	sailingWindowHeader: string | null
	sailingWindow: string | null
	eligibleItineraries: string | null
	eligibleItinerariesHeader: string | null
	redemptionRequirementHeader: string | null
	redemptionRequirement: string | null
	bookingOfferHeader: string | null
	bookingOffer: {
		markdown: string | null
		plaintext: string | null
	}
	combinableWithHeader: string | null
	combinableWith: string | null
	moreInfoHeader: string
	moreInfoDescription: {
		markdown: string
		plaintext: string
	}
	termsAndConditionsHeader: string
	termsAndConditionsDescription: {
		markdown: string
		plaintext: string
	}
	pdfFlyer: AEMPromotionMarketingAssetLink
	marketingAssetsHeader: string
	marketingAssetLinks: AEMPromotionMarketingAssetLink[]
	socialShareHeader: string
	rallioIcon?: AEMPromotionSocialIcon
	facebookIcon?: AEMPromotionSocialIcon
	instagramIcon?: AEMPromotionSocialIcon
	xIcon?: AEMPromotionSocialIcon
	_path: string
}

export interface AEMContentParams {
	locale?: string
	role?: string
	office?: string
	country?: string
	path?: string
	brandCode?: string
	collection?: string
	offerName?: string
	offerFor?: string
	ship?: string
	destination?: string
	offerType?: string
	brand?: string
	tag?: string
	contentPath?: string
	shipCode?: string
	profile?: string
	stateroomSubtypeCode?: string
	today?: string
	"header.brand"?: string
	"header.application"?: string
	"header.language"?: string
	startDate?: string
}

export interface AEMTag {
	id: string
	name: string
	title: string
}

export interface FilterOption {
	value: string
	label: string
}

export enum AEMPageType {
	HOME = "home",
	LOGIN = "login",
	FOOTER = "footer",
	PROMOTIONS_HOME = "promotions-home",
	FEATURED_OFFERS = "featured-offers",
	ARCHIVED_OFFERS = "archived-offers",
	OFFERS = "offers-name",
	EXPIRED_OFFERS = "expired-offers",
	SEE_OUR_SHIPS = "see-our-ships",
	LANGUAGES = "languages",
	DICTIONARY = "dictionary",
	ALERTS = "alerts",
	NOTIFICATIONS = "notifications",
	NAVIGATION_FOOTER = "navigation-footer",
	NAVIGATION_GLOBAL = "navigation-global",
	NAVIGATION_SIDEBAR = "navigation-sidebar",
	NAVIGATION_HOME = "navigation-home",
	PAGES_HOME = "pages-home",
	PAGES_PRIVACY_POLICY = "privacy-policy",
	PAGES_TRAVEL_AGENT_GUIDELINES = "travel-agent-guidelines",
	PAGES_TERMS_OF_USE = "terms-of-use",
	PAGES_INSIGHTS_ALERT_CENTER = "insights-alert-center",
	PAGES_INSIGHTS_COUPON_DETAILS = "insights-coupon-application-details",
	PAGES_INSIGHTS_FUTURE_COMPENSATION = "insights-future-compensation",
	PAGES_INSIGHTS_INDIVIDUAL_RESERVATIONS = "insights-individual-reservations",
	PAGES_INSIGHTS_NPC_TRANSMISSION = "insights-npc-transmission-details",
	PAGES_INSIGHTS_OVERVIEW = "insights-overview",
	PAGES_INSIGHTS_AUTOMATED_EMAILS = "insights-set-up-automated-emails",
	PAGES_INSIGHTS_VIEW_BRANCH = "insights-view-another-branch",
	PAGES_PROMOTIONS_BRAND_LANDING_ROYAL = "promotions-brand-landing-royal",
	PAGES_PROMOTIONS_OCTOBER_OFFER = "promotions-october-offer",
	PAGES_PROMOTIONS_SEARCH_RESULTS = "promotions-search-results",
	PAGES_SEARCH_RESULTS = "search-results",
	TAGS = "tags",
	ICONS = "icons",
	SEE_OUR_SHIPS_OVERVIEW = "see-our-ships-overview",
	SEE_OUR_SHIPS_ENTERTAINMENT = "see-our-ships-entertainment",
	SEE_OUR_SHIPS_ACTIVITIES = "see-our-ships-activities",
	SEE_OUR_SHIPS_BAR_AND_LOUNGE = "see-our-ships-bar-and-lounge",
	SEE_OUR_SHIPS_ROOMS = "see-our-ships-rooms",
	SEE_OUR_SHIPS_FLEET = "see-our-ships-fleet",
	SEE_OUR_SHIPS_DINING = "see-our-ships-dining",
	SEE_OUR_SHIPS_SPECIALTY_DINING = "see-our-ships-specialty-dining",
	SEE_OUR_SHIPS_COMPLIMENTARY_DINING = "see-our-ships-complimentary-dining",
	DINING = "dining",
	SEE_OUR_SHIPS_DECK_PLANS = "see-our-ships-deck-plans",
	SEE_OUR_SHIPS_NEIGHBOURHOODS = "see-our-ships-neighbourhoods",
	SOS_ENTITY_ROOMS = "rooms",
	STATEROOM_SUBTYPE = "stateroom-subtype-content",
	ENTERTAIMENT = "entertainment",
	STATEROOM_SUBTYPE_DETAIL = "stateroom-subtype-detail-content",
	DECKPLAN = "deck-content",
	SHIP_PROFILE_CONTENT = "ship-profile-content"
}

export interface AEMPagePath {
	id: AEMPageType
	path: string
}

export const AEMPagePaths: Record<AEMPageType, string> = {
	[AEMPageType.HOME]: "/home",
	[AEMPageType.PAGES_HOME]: "/pages/home",
	[AEMPageType.LOGIN]: "/login",
	[AEMPageType.FOOTER]: "/footer",
	[AEMPageType.PROMOTIONS_HOME]: "/pages/promotions-home",
	[AEMPageType.FEATURED_OFFERS]: "/pages/featured-offers",
	[AEMPageType.ARCHIVED_OFFERS]: "/pages/archived-offers",
	[AEMPageType.OFFERS]: "/offers",
	[AEMPageType.EXPIRED_OFFERS]: "/expired-offers",
	[AEMPageType.SEE_OUR_SHIPS]: "/pages/sos-home",
	[AEMPageType.LANGUAGES]: "/languages",
	[AEMPageType.DICTIONARY]: "/dictionary",
	[AEMPageType.ALERTS]: "/alerts",
	[AEMPageType.NOTIFICATIONS]: "/notifications",
	[AEMPageType.NAVIGATION_FOOTER]: "/navigation/footer",
	[AEMPageType.NAVIGATION_GLOBAL]: "/navigation/global",
	[AEMPageType.NAVIGATION_SIDEBAR]: "/navigation/sidebar",
	[AEMPageType.NAVIGATION_HOME]: "/navigation/home",
	[AEMPageType.PAGES_PRIVACY_POLICY]: "/pages/privacy-policy",
	[AEMPageType.PAGES_TRAVEL_AGENT_GUIDELINES]: "/pages/travel-agent-guidelines",
	[AEMPageType.PAGES_TERMS_OF_USE]: "/pages/terms-of-use",
	[AEMPageType.PAGES_INSIGHTS_ALERT_CENTER]: "/pages/insights/alert-center",
	[AEMPageType.PAGES_INSIGHTS_COUPON_DETAILS]:
		"/pages/insights/coupon-application-details",
	[AEMPageType.PAGES_INSIGHTS_FUTURE_COMPENSATION]:
		"/pages/insights/future-compensation",
	[AEMPageType.PAGES_INSIGHTS_INDIVIDUAL_RESERVATIONS]:
		"/pages/insights/individual-reservations",
	[AEMPageType.PAGES_INSIGHTS_NPC_TRANSMISSION]:
		"/pages/insights/npc-transmission-details",
	[AEMPageType.PAGES_INSIGHTS_OVERVIEW]: "/pages/insights/overview",
	[AEMPageType.PAGES_INSIGHTS_AUTOMATED_EMAILS]:
		"/pages/insights/set-up-automated-emails",
	[AEMPageType.PAGES_INSIGHTS_VIEW_BRANCH]:
		"/pages/insights/view-another-branch",
	[AEMPageType.PAGES_PROMOTIONS_BRAND_LANDING_ROYAL]:
		"/pages/promotions-brand-landing-royal",
	[AEMPageType.PAGES_PROMOTIONS_OCTOBER_OFFER]:
		"/pages/promotions-offers/october-2024-monthlong-offer",
	[AEMPageType.PAGES_PROMOTIONS_SEARCH_RESULTS]:
		"/pages/promotions-search-results",
	[AEMPageType.TAGS]: "/tags",
	[AEMPageType.ICONS]: "/icons",
	[AEMPageType.PAGES_SEARCH_RESULTS]: "/pages/search-results",
	[AEMPageType.SEE_OUR_SHIPS_OVERVIEW]: `/pages/${EntityPathParams.BRAND}-overview${EntityPathParams.SHIP}`,
	[AEMPageType.SEE_OUR_SHIPS_FLEET]: `/pages/${EntityPathParams.BRAND}-fleet`,
	[AEMPageType.SEE_OUR_SHIPS_DINING]: `/pages/${EntityPathParams.BRAND}-dining${EntityPathParams.SHIP}`,
	[AEMPageType.SEE_OUR_SHIPS_SPECIALTY_DINING]: `/pages/${EntityPathParams.BRAND}-specialty-dining${EntityPathParams.SHIP}`,
	[AEMPageType.SEE_OUR_SHIPS_COMPLIMENTARY_DINING]: `/pages/${EntityPathParams.BRAND}-complimentary-dining${EntityPathParams.SHIP}`,
	[AEMPageType.SEE_OUR_SHIPS_ENTERTAINMENT]: `/pages/${EntityPathParams.BRAND}-entertainment${EntityPathParams.SHIP}`,
	[AEMPageType.SEE_OUR_SHIPS_ACTIVITIES]: `/pages/${EntityPathParams.BRAND}-activities${EntityPathParams.SHIP}`,
	[AEMPageType.SEE_OUR_SHIPS_BAR_AND_LOUNGE]: `/pages/${EntityPathParams.BRAND}-bars-lounges${EntityPathParams.SHIP}`,
	[AEMPageType.SEE_OUR_SHIPS_ROOMS]: `/pages/${EntityPathParams.BRAND}-rooms${EntityPathParams.SHIP}`,
	[AEMPageType.DINING]: "/dining",
	[AEMPageType.SEE_OUR_SHIPS_DECK_PLANS]: `/pages/${EntityPathParams.BRAND}-deck-plan${EntityPathParams.SHIP}`,
	[AEMPageType.SEE_OUR_SHIPS_NEIGHBOURHOODS]: `/pages/${EntityPathParams.BRAND}-neighbourhoods${EntityPathParams.SHIP}`,
	[AEMPageType.SOS_ENTITY_ROOMS]: "/rooms",
	[AEMPageType.STATEROOM_SUBTYPE]: "/stateroom-subtype-content",
	[AEMPageType.ENTERTAIMENT]: "/entertainment",
	[AEMPageType.DECKPLAN]: "/deck-content",
	[AEMPageType.STATEROOM_SUBTYPE_DETAIL]: "/stateroom-subtype-detail-content",
	[AEMPageType.SHIP_PROFILE_CONTENT]: "/ship-profile-summary"
}

export enum AEMComponentMapper {
	WELCOME = "ComponentWelcomeModel",
	ESPRESSO_SEARCH_BAR = "ComponentEspressoBookingModel",
	ALERTS_SECTION = "ComponentBubbleTilesModel",
	CRUISE_ENHANCEMENTS = "ComponentBubbleTilesModel",
	MARKETING_CENTER = "ComponentBubbleTilesModel",
	PROMOTIONS_SEARCH_HOMEPAGE = "ComponentPromoSearchModel",
	FEATURE_OFFERS = "Component2UpCardsModel",
	ARCHIVED_OFFERS = "Component50_50ImageAndTextModel",
	TRAINING_AND_CERTIFICATION = "ComponentTrainingAndCertificationModel",
	LOYALTY_REWARDS = "ComponentLoyaltyRewardsModel",
	SHIP_LOCATOR = "ComponentShipLocatorModel",
	LEADERS_MODULE = "ComponentGroupLeadersModel",
	SIDE_IMAGE_SECTION = "Component50_50SliderModel",
	LOGIN_HERO = "ComponentBackgroundHeroModel",
	LOGIN_FORM = "ComponentLoginFormModel",
	LOGIN_ASSISTANCE_MODAL = "ComponentLoginAssistanceModalModel",
	APP_FOOTER = "ComponentFooterModel",
	APP_NAVIGATION_FOOTER = "ComponentAppNavigationFooterModel",
	APP_LANGUAGES = "ComponentLanguagesModel",
	APP_DICTIONARY = "ComponentDictionaryModel",
	APP_GLOBAL_NAVIGATION = "ComponentGlobalNavigationModel",
	APP_NAVIGATION_SIDEBAR = "ComponentNavigationSidebarModel",
	PROMOTIONS_HOME = "ComponentRowOfCardsModel",
	APP_ALERTS = "ComponentAlertBannerList",
	APP_NOTIFICATIONS = "ComponentNotificationsList",
	PROMOTIONS_BRAND = "ComponentBrandSwitcherModel",
	ROW_OF_CARDS_SECTION = "ComponentRowOfCardsModel",
	SLIDER_CAROUSEL_SECTION = "ComponentCardSliderModel",
	IMAGE_BACKGROUND_SECTION = "ComponentImageBackgroundSectionModel",
	ICONS_GRID_SECTION = "ComponentIconsGridSectionModel",
	GALLERY_IMAGES_SECTION = "ComponentCollageGalleryModel",
	BUTTONS_CAROUSEL_SECTION = "ComponentButtonsCarouselSectionModel",
	PAGE_HEADER_SECTION = "ComponentIntroModel",
	SHIPS_SECTION = "ComponentFleetCardListingModel",
	INFINITE_CAROUSEL_SECTION = "ComponentCarouselGalleryModel",
	SIDE_CAROUSEL_SECTION = "ComponentSideCarouselSectionModel",
	ACTIVITY_DETAIL_TITLE_SECTION = "ComponentActivityDetailTitleSectionModel",
	STATIC_CAROUSEL_SECTION = "ComponentRowGalleryModel",
	FEATURED_OFFERS_INTRO_MODEL = "ComponentIntroModel",
	FEATURED_OFFERS_SEARCH_MODEL = "ComponentOffersSearchModel",
	TAGS_OFFER_NAME = "cruising-power:offer/offer-name",
	TAGS_OFFER_FOR = "cruising-power:offer/offer-for",
	TAGS_OFFER_COLLECTION = "cruising-power:offer/collection",
	OFFERS = "ComponentOffers",
	HERO_MODEL = "ComponentHeroModel",
	SLIDER_CAROUSEL_LIGTH_SECTION = "ComponentSliderCarouselLigthSectionModel",
	FILTER_BAR_MODEL = "ComponentFilterBarModel",
	DINING_CARD = "ComponentDiningCardModel",
	FULL_IMAGE_GALLERY = "ComponentFullImageGalleryModel",
	FOOTER_SECTION = "ComponentFooterSectionModel",
	SHIP_DETAILS = "ShipModel",
	SOS_ENTITY_DETAILS_MODEL = "ComponentEntityModel",
	OFFER_DETAILS = "ComponentOfferDetails",
	ENTERTAIMENT = "ComponentEntertainmentModel",
	SPECIALTY_DINING_CARD_TYPE = "cruising-power:type/specialty-dining",
	TOUR_3D_SECTION = "Component3dTourModel",
	DESCRIPTION_MODEL = "ComponentDescriptionModel",
	FULL_WIDTH_BG_AND_TEXT = "ComponentFullWidthBgAndTextModel"
}

export enum AEMSliderTypes {
	NO_SLIDE = "noSlide",
	NO_SLIDE_KEBAB_CASE = "no-slide",
	NO_IMAGE = "noImage",
	ACCOLADES_GALLERY = "accoladesGallery",
	FEATURED_IMAGE = "featuredImage",
	GALLERY = "gallery"
}

export enum AEMResourceTypes {
	OFFER = "offer",
	ITINERARY = "itinerary"
}

export interface AEMSliderModelProps {
	type: string
	sliderType: AEMSliderTypes
	contentAlignment: MediaPositionType
	featuredImage: AEMImageData
	headline: string
	headlineDescription: {
		markdown: string
		plaintext?: string
	}
	additionalInfoHeaders: string[]
	additionalInfo: string[]
	sideNavLabel: string
	primaryLinkText?: string
	primaryLinkUrl?: string
	primaryLinkType?: string
	primaryLinkAppId?: string
	primaryLinkToolCode?: string
	primaryLinkRetainBasePath?: boolean
	backgroundImage: {
		path: string
		title: string
		description: string
		width: number
		height: number
	}
	gallery: Array<{
		publishUrl: string
		path: string
		title: string
		description: string
	}>
	title: string
	// TODO(Santiago Ponce): These properties are added from us since they are not coming from AEM, have to check if they are needed with the AEM Team
	link?: LinkItem
	fullScreen?: boolean
	subTitle?: string
	highlightsList?: string[]
	highlightsLabel?: string
	contentReference?: AEMSliderModelProps[]
	backgroundColor?: string
}

export interface BrandHeroType {
	type: string
	brandName: string
	brandCode: string
	icon: IconHero
}

interface IconHero {
	publishUrl: string
	path: string
	title: string
	description: string
}

export interface DescriptionModelProps {
	type?: string
	resource?: string
	title?: string
	description?: Description
	backgroundImage?: AEMImageData
	backgroundColor?: boolean
}
