import { useAEMComponentData } from "@AEM/AEMContentLoader"
import { AEMComponentMapper, AEMPageType } from "@AEM/models/AEMPage.model"
import {
	Anchor,
	Box,
	Divider,
	Modal,
	ModalProps,
	Stack,
	Text
} from "@mantine/core"

import XIcon from "@assets/svg/x.svg?react"

import styles from "./AssistanceModal.module.css"
import { eventTrackingItems } from "@utils/eventTracking"

export type AssistanceModalContactMethod = {
	label: string
	value: string
}

export function AssistanceModal(props: ModalProps) {
	const { componentData } = useAEMComponentData(
		AEMPageType.DICTIONARY,
		AEMComponentMapper.APP_DICTIONARY
	)

	const handlePhoneClick = () => {
		eventTrackingItems({
			event_name: "click",
			link_url: `tel:${componentData?.furtherAssistanceModalPhone}`,
			category: "",
			interactionType: "",
			navLinkText: componentData?.furtherAssistanceModalPhone
		})
	}

	const handleEmailClick = () => {
		eventTrackingItems({
			event_name: "click",
			link_url: `mailto:${componentData?.furtherAssistanceModalEmail}`,
			category: "",
			interactionType: "",
			navLinkText: componentData?.furtherAssistanceModalPhone
		})
	}

	return (
		componentData && (
			<Modal
				{...props}
				centered
				size="lg"
				classNames={{
					header: styles.modalHeader,
					content: styles.modalContent,
					body: styles.modalBody
				}}
				closeButtonProps={{
					"aria-label": "Close Assistance Modal",
					icon: <XIcon viewBox={"6 6 12 12"} width={16} height={16} />
				}}
			>
				<Stack className={styles.container}>
					<Stack className={styles.innerContainer}>
						<Text fz={22} fw={600} lh={1.4} ta="center">
							{componentData.furtherAssistanceModalTitle}
						</Text>
						<Text
							fz={13}
							fw={400}
							lh={1.5}
							c="var(--text-color-grey)"
							ta="center"
						>
							{componentData.furtherAssistanceModalBody}
						</Text>
					</Stack>
					<Divider />
					<Stack className={styles.innerContainer}>
						<Text fz={16} fw={600} lh={1.9} ta="center">
							{componentData.furtherAssistanceModalSubheader}
						</Text>
						<Text
							c="var(--text-color-grey)"
							fz={13}
							fw={400}
							lh={1.6}
							ta="center"
						>
							{componentData.furtherAssistanceModalSubtext}
						</Text>
						<Box ta="center">
							<Box>
								<Text span c="var(--text-color-grey)" fz={13} fw={400} lh={1.6}>
									{`${componentData.phone} `}
								</Text>
								<Anchor
									fw={500}
									c="var(--text-color-grey)"
									fz={13}
									lh={1.6}
									href={`tel:${componentData.furtherAssistanceModalPhone}`}
									onClick={handlePhoneClick}
								>
									{componentData.furtherAssistanceModalPhone}
								</Anchor>
							</Box>
							<Box>
								<Text span c="var(--text-color-grey)" fz={13} fw={400} lh={1.6}>
									{`${componentData.email} `}
								</Text>
								<Anchor
									fz={13}
									fw={500}
									href={`mailto:${componentData.furtherAssistanceModalEmail}`}
									onClick={handleEmailClick}
								>
									{componentData.furtherAssistanceModalEmail}
								</Anchor>
							</Box>
						</Box>
						<Text c="var(--text-color-grey)" fz={11} fw={400} lh={1.9}>
							{componentData.furtherAssistanceModalOpenHours}
						</Text>
					</Stack>
				</Stack>
			</Modal>
		)
	)
}
